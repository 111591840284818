<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-9 pt-0 pb-2">
        <h1>All Users</h1>
      </v-col>
      <v-col class="col-3 pt-0 pb-2 text-right">
        <v-btn v-if="showArchived === false" @click="showArchived = true">View archived users</v-btn>
        <v-btn v-if="showArchived === true" @click="showArchived = false">View current users</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12 pt-0 pb-0">
        <router-link to="/admin/user-create">
          <v-btn small>
            Add new user
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">User group</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(user, index) in users"
                :key="index"
              >
                <td>{{ user.name }}</td>
                <td>{{ user.user_group }}</td>
                <td>
                  <router-link custom v-slot="{ navigate }" :to="`/admin/user-view/${user.id}`">
                    <v-btn
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                      View
                    </v-btn>
                  </router-link>
                  &nbsp;
                  <router-link custom v-slot="{ navigate }" :to="`/admin/user-edit/${user.id}`">
                    <v-btn
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                      Edit
                    </v-btn>
                  </router-link>
                  &nbsp;
                  <v-btn
                    v-if="showArchived === false"
                    x-small
                    @click="archive(index)"
                    Archive>Archive</v-btn>
                  <v-btn
                    v-else
                    x-small
                    @click="unArchive(index)"
                    Archive>Un-Archive</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'AdminUsersIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      users: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      showArchived: false,
    };
  },
  watch: {
    page() {
      this.getUsers();
    },
    showArchived() {
      this.getUsers();
    }
  },
  methods: {
    getUsers() {
      axios.get(`/users/getAll/${this.page}/${this.showArchived}.json?token=${this.token}`)
        .then((response) => {
          this.users = response.data.users;
          this.length = response.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    archive(index) {
      let user = {};
      user = this.users[index].id;
      axios.get(`/users/archive/${user}.json?token=${this.token}`)
        .then((response) => {
          if (response.data === true) {
            this.users.splice(index, 1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    unArchive(index) {
      let user = {};
      user = this.users[index].id;
      axios.get(`/users/unarchive/${user}.json?token=${this.token}`)
        .then((response) => {
          if (response.data === true) {
            this.users.splice(index, 1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>
